import React, { useState } from 'react';
import '../styles/nav.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Link } from 'react-router-dom';
import logo from '../assets/kim.png';

const Nav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('Home');

  const handleSetActive = (link) => {
    setActiveLink(link);
    setIsMenuOpen(false); // Close the menu on link click
  };

  return (
    <div className="Nav" id='home'>
      <nav>
        <Link to='/'>
          <img src={logo} alt="Kim logo" className="logo" />
        </Link>
        <div className={`center ${isMenuOpen ? 'open' : ''}`}>
          <Link
            to="/"
            className={activeLink === 'Home' ? 'active' : ''}
            onClick={() => handleSetActive('Home')}
          >
            Home
          </Link>
          <a
            href="#about"
            className={activeLink === 'About' ? 'active' : ''}
            onClick={() => handleSetActive('About')}
          >
            About
          </a>
          <a
            href="#exp"
            className={activeLink === 'Experience' ? 'active' : ''}
            onClick={() => handleSetActive('Experience')}
          >
            Work Experience
          </a>
          <a
            href="#contact"
            className={activeLink === 'Contact' ? 'active' : ''}
            onClick={() => handleSetActive('Contact')}
          >
            Contact me
          </a>
          <Link className="envelope">
            <i className="bi bi-envelope" style={{ fontSize: '23px' }}></i>
          </Link>
          <Link className="details" download>
            Detailed Report
          </Link>
        </div>
        <div className="right">
          <button className="menu-toggle" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <i className="bi bi-list" style={{ fontSize: '30px' }}></i>
          </button>
          <Link className='envelope'>
            <i className="bi bi-envelope" style={{ fontSize: '23px' }}></i>
          </Link>
          <Link className='details' download>
            Detailed Report
          </Link>
        </div>
      </nav>
    </div>
  );
};

export default Nav;