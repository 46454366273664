// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Index from './components/assembler/Index';
import ForOFor from './components/pages/ForOFor';
import Layout from './components/Layout';

function App() {
  return (
    <div className="App">
      <Router>
        <Layout>
          <Switch>
            <Route exact path="/" component={Index} />
            <Route path="*" component={ForOFor} />
          </Switch>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
