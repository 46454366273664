import '../styles/more.scss'
import img1 from '../assets/WhatsApp Image 2024-06-28 at 10.12.49 AM (2).jpeg'
import img2 from '../assets/WhatsApp Image 2024-06-28 at 10.12.50 AM (1).jpeg'
import img3 from '../assets/WhatsApp Image 2024-06-28 at 10.12.51 AM.jpeg'
import img4 from '../assets/WhatsApp Image 2024-06-28 at 10.12.49 AM (1).jpeg'
const MoreAboutKim = function(){
    return(
      <>
        <div className="more-about-kim" id='about'>
            <div className="left">
                <img src={img1} alt="" />
                <img src={img2} alt="" />
                <img src={img3} alt="" />
                <img src={img4} alt="" />
                <div className="center">
                    <h2>22</h2>
                    <p>Years Of Experience</p>
                </div>
            </div>

            <div className="right">
                <span>
                Kim Luna Davidson
                </span>
                <h2>Premium Financial Consultant.</h2>
                <p>I have accumulated enough skills and experience within the market to help manage your investments and ensure consistent profitability.</p>
                <p>Throughout the course of my career, I have been registered with two prestigious investment firms, passed three brokerage examinations, and gotten accreditation with licenses in about twenty-five states in the US.</p>
                <div className='group'>
                    <div>
                    <i className="bi bi-chat-left"></i>
                        <h3>Business development with Companies</h3>
                    </div>
                    <div>
                    <i className="bi bi-sliders2"></i>
                        <h3>Automated solutions for best result</h3>
                    </div>
                </div>
            </div>
        </div>
     </>
    )
}
export default MoreAboutKim;