// src/components/pages/ForOFor.js
import React from 'react';
import { Helmet } from 'react-helmet';

const ForOFor = () => {
  return (
    <div>
      <Helmet>
        <title>404 - Page Not Found</title>
        <meta name="description" content="The page you are looking for does not exist." />
      </Helmet>
      <h1>404 - Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
    </div>
  );
};

export default ForOFor;
