import '../styles/hero.scss'
// import { Link } from "react-router-dom";
import img from '../assets/plap.jpeg'
const Hero = function(){
    return(
      <>
        <div className="hero">
          <div className="hero-child">
          <div className="left">
                <span>Stock Broker & Investment Adviser</span>
                <h2>Kim Luna Davidson</h2>
                <p>I manage investment portfolios of high-class, exclusive firms & individuals from all over the world.</p>
                <a href='#about'>Learn more</a>
            </div>
            <div className="right">
               <img src={img} alt="" />
            </div>
          </div>
          <a href='#contact' className='conatct-email'><i className="bi bi-envelope" style={{fontSize:'23px'}}></i> contact@KimLunaDavidson.com</a>

        </div>
        
        <div className="tools">
            <div className="financial">
            <i className="bi bi-gear-fill"></i>
            <span>Financial Automation</span>
            </div>

            <div className="Investment">
            <i className="bi bi-journals"></i>
                <span>Investment Advisory</span>
            </div>

            <div className="Stock">
            <i className="bi bi-display"></i>
                <span>Stock Brokerage</span>
            </div>
        </div>
        </>
    )
}
export default Hero;