// import { Link } from "react-router-dom"
import img from '../assets/WhatsApp Image 2024-06-28 at 10.12.51 AM (1).jpeg'
import '../styles/havingdiff.scss'

const HavingDifficulties = function () {
  return (
    <div className="Having-difficulties">

      <div className="box1">
        <div className="left">
          <h4 className="view">Work With Me</h4>
          <h1 className="view">Having difficulties with your Portfolio?</h1>
        </div>
        <div className="right">
        <a href='#contact' className='contact'>Contact me</a>
        </div>
      </div>


      <div className="box2">

        <div className="left">
        <h4 className="view">Work With Me</h4>
        <h1>My Examinations</h1>
        <img src={img} alt="" />
        </div>
        
        <div className="right">
            <div className="wrap">
               <i className="bi bi-journals"></i>
                <h2>Series 63 - Uniform Securities Agent State Law Examination | May 17, 2007</h2>
                <span>State Securities Law Exam</span>
            </div>

            <div className="wrap">
               <i className="bi bi-journals"></i>
                <h2>SIE - Securities Industry Essentials Examination | Oct 1, 2018</h2>
                <span>General Industry/Products Exam</span>
            </div>

            <div className="wrap">
               <i className="bi bi-journals"></i>
                <h2>Series 6 - Investment Company Products/Variable Contracts Representative Examination | Feb 7, 2005</h2>
                <span>General Industry/Products Exam</span>
            </div>
        </div>
      </div>


    </div>
  );
};

export default HavingDifficulties;
