// src/components/Layout.js
import React from 'react';
import { Helmet } from 'react-helmet';

const Layout = ({ children }) => {
  return (
    <div>
      <Helmet>
        <title>Kim Luna Davidson</title>
        <meta name="description" content="Welcome to Kim Luna Davidson's website. Discover amazing content and insights." />
        <meta name="keywords" content="Kim Luna Davidson, web development, React, SEO" />
        <meta name="author" content="Kim Luna Davidson" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Person",
            "name": "Kim Luna Davidson",
            "url": "https://www.kimlunadavidson.com",
            "sameAs": [
              "https://www.facebook.com/kimlunadavidson",
              "https://www.twitter.com/kimlunadavidson",
              "https://www.linkedin.com/in/kimlunadavidson"
            ],
            "jobTitle": "Web Developer",
            "worksFor": {
              "@type": "Organization",
              "name": "My Company"
            }
          })}
        </script>
      </Helmet>
      {children}
    </div>
  );
};

export default Layout;
