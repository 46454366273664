import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../styles/footer.scss'; // Adjust the path as needed

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="left-section">
          <h2>Kim Luna</h2>
          <p>Investment Adviser | Stock Broker</p>
          <i className="bi bi-envelope-fill email-icon"></i>
        </div>
        <div className="right-section">
          <h3>About Me</h3>
          <p>
            <i className="bi bi-envelope-fill email-icon"></i> contact@kimlunadavidson.com
          </p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>All rights reserved Kim Luna Davidson © 2024</p>
      </div>
    </footer>
  );
}

export default Footer;
