import '../styles/experience.scss' 

const Experience = function(){
    return(
        <div className="experience" id='exp'>
            <div className="top">
                <p>Stock Broker | Investment Adviser</p>    
                <h2>Work Experience</h2>
                <marquee behavior="smooth" direction="left"> <span></span> <span></span> </marquee>
            </div>

            <div className="bottom">

                <div className="box">
                <i className="bi bi-file-text"></i>
                    <p className='p'>2005 - Present (19 years)</p>
                    <h2>NEWEDGE SECURITIES, INC. (CRD# 10674)</h2>
                    <p className='pp'>Stock Broker</p>
                </div>

                <div className="box">
                <i className="bi bi-file-text"></i>
                    <p className='p'>1998 - 2001 (3 years)</p>
                    <h2>PNC BROKERAGE CORP (CRD# 34671)</h2>
                    <p className='pp'>Stock Broker</p>
                </div>

            </div>
        </div>
    )
}

export default Experience;