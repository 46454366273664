import '../styles/clientReview.scss'
import img from '../assets/image8.jpg'
import img1 from '../assets/48.jpg'
const ClientReview = function(){
    return(
        <div className="client-review">
            <div className="top">
                <p>Kim Luna Davidson</p>
                <h2>Client Reviews</h2>
                <marquee behavior="smooth" direction="left"><span></span><span></span></marquee>
            </div>

            <div className="bottom">
            <img src={img} alt=""  className='leftimg'/>
                <div  behavior="smooth" direction="left" className="right m">
                    <div className="wrap">
                        <h3 className="text">
                            I never knew i could trade the market and profit on my own till i met Kim Luna Davidson. She literally changed my life, and now i trade constantly while handling my main business
                        </h3>
                       <div className="reviewer">
                        <img src={img1} alt="" />
                       <span>Pradeep Harrison, Retail Contractor</span>
                       </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ClientReview