import { Link } from "react-router-dom"
import img from '../assets/WhatsApp Image 2024-06-28 at 10.12.48 AM.jpeg'
import img1 from '../assets/WhatsApp Image 2024-06-28 at 10.12.49 AM.jpeg'
import '../styles/reviewandinvest.scss'
const ReviewAndInvest = function(){
    return(
        <div className="review-and-invest">
            <div className="container">
                <img src={img} alt="" />
                <div className="box">
                    <p className="p">Kim Luna Davidson</p>
                    <h2 className="lets">Let's review your Portfolio together</h2>
                    <p className="text">I work with clients from all over the world including private firms and business organizations. With primary focus on stocks & other unique investment opportunities, I can help build your portfolio to consistently generate enough profit to keep you ahead in the financial markets.</p>
                    <Link to='' className='contact'>Contact me</Link>
                </div>
            </div>

            <div className="container">
                <div className="box">
                    <p className="p">Kim Luna Davidson</p>
                    <h2 className="h2">Investment Adviser</h2>
                  <div className="wrap">
                    <div className="box">
                        <span>99%</span>
                        <h2>Profit Generation</h2>
                    </div>
                    <div className="box">
                        <span>99%</span>
                        <h2>Client Satisfaction</h2>
                    </div>
                    <div className="box">
                        <span>1K+</span>
                        <h2>Portfolios Handled</h2>
                    </div>
                  </div>
                    <Link to='' className='view'>View Detailed Report</Link>
                </div>
                <img src={img1} alt="" />
            </div>
        </div>
    )
}

export default ReviewAndInvest