import React from 'react';
import { Helmet } from 'react-helmet';
import ClientReview from '../pages/ClientReview';
import ContactForm from '../pages/Contact';
import Experience from '../pages/Experience';
import HavingDifficulties from '../pages/HavingDifficulties';
import Hero from '../pages/Hero';
import MoreAboutKim from '../pages/MoreAboutKim';
import Nav from '../pages/Nav';
import Footer from '../pages/Footer';
import ReviewAndInvest from '../pages/ReviewAndInvest';
import '../styles/index.scss';

const Index = function() {
    return (
        <div className='assembler'>
            <Helmet>
                <title>Home - Kim Luna Davidson</title>
                <meta name="description" content="Welcome to Kim Luna Davidson's website. Discover amazing content and insights about Kim's professional experience, client reviews, and more." />
                <meta name="keywords" content="Kim Luna Davidson, web development, React, SEO, professional experience, client reviews" />
                <meta name="author" content="Kim Luna Davidson" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Person",
                        "name": "Kim Luna Davidson",
                        "url": "https://www.kimlunadavidson.com",
                        "sameAs": [
                            "https://www.facebook.com/kimlunadavidson",
                            "https://www.twitter.com/kimlunadavidson",
                            "https://www.linkedin.com/in/kimlunadavidson"
                        ],
                        "jobTitle": "Web Developer",
                        "worksFor": {
                            "@type": "Organization",
                            "name": "My Company"
                        }
                    })}
                </script>
            </Helmet>
            <main>
                <div className="bg">
                    <Nav />
                    <Hero />
                    <MoreAboutKim />
                    <Experience />
                    <ReviewAndInvest />
                    <HavingDifficulties />
                    <ClientReview />
                    <ContactForm />
                    <Footer />
                </div>
            </main>
        </div>
    );
}

export default Index;
