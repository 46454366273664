import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../styles/contact.scss';  // Import your CSS file

function ContactForm() {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    location: '',
    date: '',
    timeZone: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_z97scxs', 'template_iq3v9em', e.target, '9fMAgz9j548LuzKu3')
      .then((result) => {
          console.log(result.text);
          alert('Message sent successfully!');
          alert('You will be contacted soon!!!');
      }, (error) => {
          console.log(error.text);
          alert('Failed to send message.');
      });
    e.target.reset();
  };

  return (
   <div className="contact" id='contact'>
     <form className="contact-form" onSubmit={handleSubmit}>
      <h2>Contact Me</h2>
      <h3>Write Me A Message</h3>
      <input type="text" name="fullName" placeholder="Full Name" onChange={handleChange} required />
      <input type="email" name="email" placeholder="Email Address" onChange={handleChange} required />
      <input type="text" name="phone" placeholder="Phone Number" onChange={handleChange} required />
      <input type="text" name="location" placeholder="Location" onChange={handleChange} required />
      <input type="date" name="date" placeholder="mm/dd/yyyy" onChange={handleChange} required />
      <input type="time" name="timeZone" placeholder="Time Zone" onChange={handleChange} required />
      <textarea name="message" placeholder="Write your message" onChange={handleChange} required></textarea>
      <button type="submit">Submit Now</button>
    </form>
   </div>
  );
}

export default ContactForm;
